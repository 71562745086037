<template>
  <div>
    <div class="mt-4">
      <v-data-table
        :headers="headers"
        :items="modalidades"
        sort-by="descricao"
        class="border"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Modalidades registradas</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-icon
              v-if="canList"
              medium
              @click="getModalidades"
              title="Atualizar"
              >mdi-refresh</v-icon
            >
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="700px">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-if="canCreate"
                  color="primary"
                  dark
                  class="mb-2"
                  v-on="on"
                  >Nova modalidade</v-btn
                >
              </template>
              <cadastro-modalidade
                :modalidade="modalidade"
                :formTitle="formTitle"
                @close="close"
                :modalidadeIndex="modalidadeIndex"
              />
            </v-dialog>

            <v-dialog v-model="dialogDelete" max-width="400px">
              <v-card>
                <v-card-title class="v-card mb-4">
                  <span class="headline">Deletar modalidade</span>
                </v-card-title>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="black darken-1" text @click="closeDeleteItem"
                    >Cancelar</v-btn
                  >
                  <v-btn
                    color="darken-1"
                    style="background: #f00; color: white"
                    @click="deletarModalidade"
                    >Deletar</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.pbt`]="{ item }">
          <span>{{ addMascaraPbt(item.pbt) }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon v-if="canUpdate" small class="mr-2" @click="editItem(item)"
            >mdi-pencil</v-icon
          >
          <v-icon v-if="canDelete" small @click="deleteItem(item)"
            >mdi-delete</v-icon
          >
        </template>
        <template #no-data>
          <v-btn v-if="canList" color="primary" @click="getModalidades"
            >Reset</v-btn
          >
          <div v-if="!canList">
            <span class="red--text"
              >Você não tem permissão para visualizar as informações desta
              tela</span
            >
          </div>
        </template>
        
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CadastroModalidade from './CadastroModalidade.vue'
import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  components: { CadastroModalidade },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Descrição', value: 'descricao' },
        { text: 'PBT', value: 'pbt' },
        { text: 'Eixo', value: 'eixo' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      modalidadeIndex: -1,
      modalidade: {
        descricao: '',
        pbt: '',
        eixo: '',
      },
      defaultItem: {
        descricao: '',
        pbt: '',
        eixo: '',
      },
    }
  },
  computed: {
    ...mapState('patio', ['modalidades']),
    ...mapState('auth', ['empresaAtual']),

    permission() {
      return Sequences.SistemaModalidades.toString()
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
    
    formTitle() {
      return this.modalidadeIndex === -1
        ? 'Adicionar nova modalidade'
        : 'Editar modalidade'
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  async created() {
    if (this.canList) {
      await this.getModalidades()
    }
  },

  methods: {
    ...mapActions('patio', [
      'getModalidades',
      'addModalidade',
      'editModalidade',
      'deleteModalidade',
    ]),

    editItem(item) {
      this.modalidadeIndex = this.modalidades.indexOf(item)
      Object.assign(this.modalidade, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.dialogDelete = true
      this.modalidadeIdDelete = item.id
    },

    closeDeleteItem() {
      this.dialogDelete = false
      this.modalidadeIdDelete = -1
    },

    deletarModalidade() {
      if (this.modalidadeIdDelete > -1) {
        this.deleteModalidade(this.modalidadeIdDelete)
      }
      this.modalidadeIdDelete = -1
      this.dialogDelete = false
    },

    close() {
      this.modalidadeIndex = -1
      this.dialog = false
    },

    addMascaraPbt(pbt) {
      const novoPbt = pbt.toString()
      if (novoPbt.length === 5) {
        return novoPbt.slice(0, 2) + '.' + novoPbt.slice(2, 5)
      } else if (novoPbt.length === 4) {
        return novoPbt.slice(0, 1) + '.' + novoPbt.slice(1, 4)
      }
      return pbt
    },
  },
}
</script>
