<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Modalidades">
          <listar-modalidade />
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListarModalidade from '@/components/administrativo/veiculo/modalidade/ListarModalidade'

export default {
  data() {
    return {
      page: {
        title: 'Gerenciamento de modalidades',
      },
      breadcrumbs: [
        {
          text: 'Gerencial',
          disabled: true,
        },
        {
          text: 'Modalidades',
          disabled: false,
        },
      ],
    }
  },
  components: {
    ListarModalidade,
  },
}
</script>

<style></style>
